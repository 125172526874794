<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">전화 상담이력변경관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class>
          <div class="form-inp sm">
            <v-select
              v-model="selected_channel"
              :items="asp_new_cust_Key"
              item-value="CD"
              item-text="CD_NM"
              outlined
              hide-details
              label="회사구분"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            content-class="calendar-modal"
            color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                v-bind="attrs"
                v-on="on"
                readonly
                outlined
                hide-details
                class="form-inp icon-calendar ml-2"
                append-icon="svg-calendar"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                v-model="dates[0]"
                no-title
                dark
                color="#F7AFAE"
                name="startDate"
                @change="startDate"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                v-model="dates[1]"
                dark
                color="#F7AFAE"
                no-title
                show-current="false"
                :min="dates[0]"
                name="endDate"
                @change="endDate"
                :first-day-of-week="1"
                locale="ko-KR"
                :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn outlined class="btn-default" @click="menu1 = false"
                >확인</v-btn
              >
            </div>
          </v-menu>
          <div class="form-inp sm ml-2">
            <v-select
              v-model="chngProcTy"
              :items="mixin_common_code_get(this.common_code, 'RE002', '전체')"
              outlined
              hide-details
              label="변경처리"
              filled
              dense
              background-color="white"
            ></v-select>
          </div>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2" v-on:click="search">조회</v-btn>
        </div>
      </div>
    </div>
    <!-- 고객정보목록 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">
        상담이력변경관리목록
        <div class="ml-auto">
          <v-btn
            v-if="this.mixin_set_btn(this.$options.name, 'btnAccess')"
            outlined class="btn-point ml-2" @click="processRtn"
            >승인</v-btn
          >
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
          dense
          height="365px"
          @click:row="showRowInfo"
          :headers="gridDataHeaders"
          :items="gridDataText"
          :items-per-page="30"
          item-key="ROW_NUMBER"
          :page.sync="page"
          hide-default-footer
          class="grid-default"
          show-select
          @page-count="pageCount = $event"
          v-model="checkedList"
          :item-class="isActiveRow"
          fixed-header
        >
          <template v-slot:item.REG_DTIM="{ item }">
            <div>{{ time2Format(item.REG_DTIM) }}</div>
          </template>
          <template v-slot:item.CUST_TEL_NO="{ item }">
            <div>{{ mixin_setPhoneNo(item.CUST_TEL_NO) }}</div>
          </template>
        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="totalVisible"
            next-icon="svg-paging-next"
            prev-icon="svg-paging-prev"
          ></v-pagination>
        </div>
      </div>
    </div>
    <!--// 상담이력변경관리목록 -->
    <!--상담이력변경관리 상세정보 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">상담이력변경관리 상세정보</h2>
      <div class="box-ct">
        <div class="table-form">
          <table>
            <colgroup>
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="226px" />
              <col width="127px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">상담시작일자</th>
                <td>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-calendar"
                        disabled
                        :value="dateFormat(contactDetail.CNSL_BEGIN_DATE)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#F7AFAE"
                      no-title
                      @input="menu2 = false"
                      disabled
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">상담시작시간</th>
                <td>
                  <v-menu
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-time"
                        disabled
                        :value="timeFormat(contactDetail.CNSL_BEGIN_TIME)"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @input="menu5 = false"
                      disabled
                    ></v-time-picker>
                  </v-menu>
                </td>
                <th scope="row">사용자명</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.CSLT_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th scope="row">녹취파일명</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.RDWT_FILE_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th scope="row">고객명</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.CUST_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담종료일자</th>
                <td>
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-calendar"
                        disabled
                        :value="dateFormat(contactDetail.CNSL_EOT_DATE)"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      color="#F7AFAE"
                      no-title
                      @input="menu3 = false"
                      disabled
                      :first-day-of-week="1"
                      locale="ko-KR"
                      :day-format="mixin_getDate"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <th scope="row">상담종료시간</th>
                <td>
                  <v-menu
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    content-class="calendar-modal"
                    dark
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        hide-details
                        class="form-inp sm icon-calendar full"
                        append-icon="svg-time"
                        disabled
                        :value="timeFormat(contactDetail.CNSL_EOT_TIME)"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="timeStep"
                      :allowed-minutes="allowedStep"
                      color="#F7AFAE"
                      format="24hr"
                      @input="menu5 = false"
                      disabled
                    ></v-time-picker>
                  </v-menu>
                </td>
                <th scope="row">전화번호</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    :value="phoneHyphen(contactDetail.SEND_MAN_NO)"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th scope="row">고객전화번호</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    :disabled="true"
                    outlined
                    hide-details
                    :value="phoneHyphen(contactDetail.CUST_TEL_NO)"
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- 상담이력 변겨전/변경후 -->
    <div class="d-flex">
      <div class="box-wrap col-6 mr-3">
        <h2 class="tit-h2 d-flex">상담이력-변경전</h2>
        <div class="table-form">
          <table>
            <caption class="hide">
              고객상세정보
            </caption>
            <colgroup>
              <col width="127px" />
              <col width="291px" />
              <col width="127px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">콜유형</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.BF_CALL_TY_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th rowspan="8">상담내용</th>
                <td rowspan="8">
                  <v-textarea
                    height="285px"
                    class="textarea-type0"
                    label
                    :value="contactDetail.BF_CNSL_CNTN"
                    no-resize
                    outlined
                    disabled
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <th scope="row">녹취파일명</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.BF_RDWT_FILE_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담유형</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.BF_CNSL_TYP_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담유형2</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.BF_CNSL_TYP_NM_2"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담유형3</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.BF_CNSL_TYP_NM_3"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담유형4</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.BF_CNSL_TYP_NM_4"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">처리결과</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.BF_PROC_RSLT_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">미처리사유</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.BF_UN_PROC_RESN_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="box-wrap flex-grow-1">
        <h2 class="tit-h2 d-flex">상담이력-변경후</h2>
        <div class="table-form">
          <table>
            <caption class="hide">
              고객상세정보
            </caption>
            <colgroup>
              <col width="127px" />
              <col width="291px" />
              <col width="127px" />
              <col width="" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">콜유형</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.AF_CALL_TY_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
                <th rowspan="8">상담내용</th>
                <td rowspan="8">
                  <v-textarea
                    height="285px"
                    class="textarea-type0"
                    label
                    :value="contactDetail.AF_CNSL_CNTN"
                    no-resize
                    outlined
                    disabled
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <th scope="row">녹취파일명</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.AF_RDWT_FILE_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담유형</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.AF_CNSL_TYP_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담유형2</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.AF_CNSL_TYP_NM_2"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담유형3</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.AF_CNSL_TYP_NM_3"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">상담유형4</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.AF_CNSL_TYP_NM_4"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">처리결과</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.AF_PROC_RSLT_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <th scope="row">미처리사유</th>
                <td>
                  <v-text-field
                    class="form-inp full"
                    v-model="contactDetail.AF_UN_PROC_RESN_NM"
                    :disabled="true"
                    outlined
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="d-flex">&nbsp;</div>
  </div>
</template>

<script>
import { mixin } from "@/mixin/mixin.js";
import api from "@/store/apiUtil.js";
import { mapGetters } from "vuex";

export default {
  name: "MENU_M110203", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  data() {
    return {
      asp_new_cust_Key: this.$store.getters["userStore/GE_USER_ROLE"].company,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      timeStep: "10:10",
      selected_channel: "",
      chngProcTy: "",
      common_code: [],
      contactDetail: [],
      checkedList: [],
      dates: [
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      ],

      gridDataHeaders: [
        {
          text: "No",
          align: "center",
          value: "ROW_NUMBER",
          width: "70px",
          sortable: true,
        },
        {
          text: "녹취청취",
          value: "BTN_01",
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "콜유형1",
          value: "AF_CALL_TY_NM",
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "변경이력일련번호",
          value: "CHNG_HIST_SEQ",
          align: " d-none",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담이력번호",
          value: "CNSL_HIST_NO",
          align: " d-none",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담이력상세번호",
          value: "CNSL_HIST_DTL_NO",
          align: " d-none",
          sortable: true,
          width: "100px",
        },
        {
          text: "변경일시",
          value: "REG_DTIM",
          align: "center",
          sortable: true,
          width: "150px",
        },
        {
          text: "요청상담사명",
          value: "REQ_CSLT_NM",
          align: "center",
          sortable: true,
          width: "130px",
        },
        {
          text: "승인상담사명",
          value: "APRV_CSLT_NM",
          align: "center",
          sortable: true,
          width: "130px",
        },
        {
          text: "변경처리구분",
          value: "CHNG_PROC_TY_NM",
          align: "center",
          sortable: true,
          width: "130px",
        },
        {
          text: "변경후녹취파일명",
          value: "AF_RDWT_FILE_NM",
          align: "center",
          sortable: true,
          width: "160px",
          cellClass: "ellipse ",
        },
        {
          text: "변경후콜유형",
          value: "AF_CALL_TY_NM",
          align: "center",
          sortable: true,
          width: "130px",
        },
        {
          text: "변경후상담유형",
          value: "AF_CNSL_TYP_NM",
          align: "center",
          sortable: true,
          width: "140px",
        },
        {
          text: "변경후상담유형2",
          value: "AF_CNSL_TYP_NM_2",
          align: "center",
          sortable: true,
          width: "140px",
        },
        {
          text: "변경후상담유형3",
          value: "AF_CNSL_TYP_NM_3",
          align: "center",
          sortable: true,
          width: "140px",
        },
        {
          text: "변경후상담유형4",
          value: "AF_CNSL_TYP_NM_4",
          align: "center",
          sortable: true,
          width: "140px",
        },
        {
          text: "변경후처리결과",
          value: "AF_PROC_RSLT_NM",
          align: "center",
          sortable: true,
          width: "130px",
        },
        {
          text: "변경후미처리사유",
          value: "AF_UN_PROC_RESN_NM",
          align: "center",
          sortable: true,
          width: "130px",
        },
        {
          text: "변경후요약",
          value: "AF_SUMM",
          align: "center",
          sortable: true,
          width: "130px",
        },
        {
          text: "변경전녹취파일명",
          value: "BF_RDWT_FILE_NM",
          align: "center",
          sortable: true,
          width: "140px",
          cellClass: "ellipse",
        },
        {
          text: "변경전콜유형",
          value: "BF_CALL_TY_NM",
          align: "center",
          sortable: true,
          width: "130px",
        },
        {
          text: "변경전상담유형",
          value: "BF_CNSL_TYP_NM",
          align: "center",
          sortable: true,
          width: "140px",
        },
        {
          text: "변경전상담유형2",
          value: "BF_CNSL_TYP_NM_2",
          align: "center",
          sortable: true,
          width: "140px",
        },
        {
          text: "변경전상담유형3",
          value: "BF_CNSL_TYP_NM_3",
          align: "center",
          sortable: true,
          width: "140px",
        },
        {
          text: "변경전상담유형4",
          value: "BF_CNSL_TYP_NM_4",
          align: "center",
          sortable: true,
          width: "140px",
        },
        {
          text: "변경전처리결과",
          value: "BF_PROC_RSLT_NM",
          align: "center",
          sortable: true,
          width: "130px",
        },
        {
          text: "변경전미처리사유",
          value: "BF_UN_PROC_RESN_NM",
          align: "center",
          sortable: true,
          width: "140px",
        },
        {
          text: "변경전요약",
          value: "BF_SUMM",
          align: "center",
          sortable: true,
          width: "120px",
        },
        {
          text: "상담시작일자",
          value: "CNSL_BEGIN_DATE",
          align: " d-none",
          sortable: true,
          width: "130px",
        },
        {
          text: "상담종료일자",
          value: "CNSL_EOT_DATE",
          align: " d-none",
          sortable: true,
          width: "130px",
        },
        {
          text: "상담시작시간",
          value: "CNSL_BEGIN_TIME",
          align: " d-none",
          sortable: true,
          width: "130px",
        },
        {
          text: "상담종료시간",
          value: "CNSL_EOT_TIME",
          align: " d-none",
          sortable: true,
          width: "130px",
        },
        {
          text: "콜유형",
          value: "CALL_TY_NM",
          align: " d-none",
          sortable: true,
          width: "100px",
        },
        {
          text: "상담원명",
          value: "CSLT_NM",
          align: " d-none",
          sortable: true,
          width: "100px",
        },
        {
          text: "전화번호",
          value: "SEND_MAN_NO",
          align: "center",
          sortable: true,
          width: "100px",
        },
        {
          text: "조합명",
          value: "CU_NM",
          align: " d-none",
          sortable: true,
          width: "100px",
        },
        {
          text: "증권번호",
          value: "SECU_NO",
          align: " d-none",
          sortable: true,
          width: "100px",
        },
        {
          text: "고객전화번호",
          value: "CUST_TEL_NO",
          align: "center",
          sortable: true,
          width: "130px",
        },
        {
          text: "고객명",
          value: "CUST_NM",
          align: "center",
          sortable: true,
          width: "120px",
        },
        {
          text: "녹취파일명",
          value: "RDWT_FILE_NM",
          align: " d-none",
          sortable: true,
          width: "130px",
        },
        {
          text: "녹취파일",
          value: "RDWT_ID",
          align: " d-none",
          sortable: true,
          width: "100px",
        },
        {
          text: "변경전상담내용",
          value: "BF_CNSL_CNTN",
          align: " d-none",
          sortable: true,
          width: "130px",
        },
        {
          text: "변경후상담내용",
          value: "AF_CNSL_CNTN",
          align: " d-none",
          sortable: true,
          width: "130px",
        },
        {
          text: "변경후처리결과",
          value: "AF_PROC_RSLT",
          align: " d-none",
          sortable: true,
          width: "130px",
        },
        {
          text: "변경후미처리사유",
          value: "AF_UN_PROC_RESN",
          align: " d-none",
          sortable: true,
          width: "140px",
        },
        {
          text: "변경전처리결과",
          value: "BF_PROC_RSLT",
          align: " d-none",
          sortable: true,
          width: "130px",
        },
        {
          text: "변경전처리사유",
          value: "BF_UN_PROC_RESN",
          align: " d-none",
          sortable: true,
          width: "130px",
        },
        {
          text: "변경후상담유형(코드)",
          value: "AF_CNSL_TYP_CD",
          align: " d-none",
          sortable: true,
          width: "130px",
        },
        {
          text: "변경후상담유형(코드)2",
          value: "AF_CNSL_TYP_CD_2",
          align: " d-none",
          sortable: true,
          width: "140px",
        },
        {
          text: "변경후상담유형(코드)3",
          value: "AF_CNSL_TYP_CD_3",
          align: " d-none",
          sortable: true,
          width: "140px",
        },
        {
          text: "변경후상담유형(코드)4",
          value: "AF_CNSL_TYP_CD_4",
          align: " d-none",
          sortable: true,
          width: "140px",
        },
        {
          text: "콜타입",
          value: "CALL_TY",
          align: " d-none",
          sortable: true,
          width: "100px",
        },
        {
          text: "변경요청내용",
          value: "CHNG_REQ_CNTN",
          align: " d-none",
          sortable: true,
          width: "130px",
        },
      ],
      gridDataText: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      totalVisible: 10,
    };
  },

  async mounted() {
    //공통코드설정
    let codeName = ["RE002"];
    this.common_code = await this.mixin_common_code_get_all(codeName);
  },

  methods: {
    startDate(e) {
      this.dates[0] = e;
    },
    endDate(e) {
      this.dates[1] = e;
    },
    allowedStep: (m) => m % 10 === 0,
    //상세정보
    showRowInfo(event, { item }) {
      this.contactDetail = item;
      console.log(this.contactDetail);
    },

    isActiveRow(item) {
      const activeClass =
        item.CHNG_HIST_SEQ === this.contactDetail.CHNG_HIST_SEQ ? "active" : "";
      return activeClass;
    },

    phoneHyphen(number) {
      //핸드폰 번호 형식 만들기
      if (!number) return "";
      var phoneNum = number.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      return phoneNum;
    },
    dateFormat(date) {
      //날짜 포멧
      if (!date) return "";
      var newDate = "";

      //공백제거
      date = date.replace(/\s/gi, "");

      if (date.length == 8) {
        newDate = date.replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3");
        return newDate;
      } else {
        return date;
      }
    },
    juminFormat(num) {
      //주민 번호 형식 만들기

      if (!num) return "";

      var juminNum = num.replace(/-/g, "").replace(/(\d{6})(\d{7})/g, "$1-$2");
      return juminNum;
    },
    timeFormat(time) {
      //접수시간 포맷
      var newTime = "";
      if (!time) return "";

      if (time.length == 6) {
        newTime =
          time.substring(0, 2) +
          ":" +
          time.substring(2, 4) +
          ":" +
          time.substring(4, 6);
        return newTime;
      } else {
        //추후 6자리 외의 데이터가 있을 경우 수정
        return time;
      }
    },
    time2Format(time) {
      //접수시간 포맷
      var newTime = "";
      if (!time) return "";

      if (time.length == 14) {
        newTime =
          time.substring(0, 4) +
          "-" +
          time.substring(4, 6) +
          "-" +
          time.substring(6, 8) +
          " " +
          time.substring(8, 10) +
          ":" +
          time.substring(10, 12) +
          ":" +
          time.substring(12, 14);
        return newTime;
      } else {
        //추후 6자리 외의 데이터가 있을 경우 수정
        return time;
      }
    },
    processRtn() {
      let chkData = this.checkedList;
      let chkDataSize = chkData.length;

      //선택된 행이 있는지 validation 체크
      if (chkDataSize == "0") {
        this.alert("선택된 데이터가 없습니다.");
        return;
      }
      this.showAlert();
    },
    alert(msg) {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: msg,
        iconClass: "svg-error-lg",
        type: "default",
      });
    },
    showAlert() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg: "내용을 저장하시겠습니까?",
        iconClass: "svg-chk-lg",
        type: "confirm",
        callYes: this.callYes,
        callNo: this.callNo,
      });
    },
    async callYes() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
      let chkList = this.checkedList;
      let errFlag = false;

      let URLData = "/api/phone/history/change-manage/confm/process";
      let HEADER_SERVICE = "phone.history.change-manage.confm";
      let HEADER_METHOD = "process";
      let HEADER_TYPE = "BIZ_SERVICE";

      for (let i in chkList) {
        let jsonData = chkList[i];
        jsonData.APRV_CSLT_ID =
          this.$store.getters["userStore/GE_USER_ROLE"].userId;

        await api
          .post(URLData, jsonData, {
            head: {
              SERVICE: HEADER_SERVICE,
              METHOD: HEADER_METHOD,
              TYPE: HEADER_TYPE,
            },
          })
          .then((response) => {})
          .catch((err) => {
            console.log("err = " + err);
            errFlag = true;
          });
      }
      if (!errFlag) {
        this.common_alert("정상 처리되었습니다.");
        this.search(); //재조회
      }
    },
    callNo() {
      console.log("call No");
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },

    //년월로 변환
    yyyyMMdd: function (value) {
      // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
      if (value == "") return "";

      // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
      var js_date = new Date(value);

      // 연도, 월, 일 추출
      var year = js_date.getFullYear();
      var month = js_date.getMonth() + 1;
      var day = js_date.getDate();

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if (month < 10) {
        month = "0" + month;
      }

      if (day < 10) {
        day = "0" + day;
      }

      // 최종 포맷 (ex - '2021-10-08')
      return year + "" + month + "" + day;
    },

    async search() {
      this.URLData = "/api/phone/history/change-manage/list";
      this.HEADER_SERVICE = "phone.history.change-manage";
      this.HEADER_METHOD = "list";
      this.HEADER_TYPE = "XML_SERVICE";

      let head = "";
      let dataa = "";

      //api 호출 부분
      await api
        .post(
          this.URLData,
          {
            ASP_NEWCUST_KEY: this.selected_channel,
            SEARCH_DT_FROM: this.yyyyMMdd(this.dates[0]),
            SEARCH_DT_TO: this.yyyyMMdd(this.dates[1]),
            CHNG_PROC_TY: this.chngProcTy,
          },
          {
            head: {
              SERVICE: this.HEADER_SERVICE,
              METHOD: this.HEADER_METHOD,
              TYPE: this.HEADER_TYPE,
              ROW_CNT: "50",
              PAGES_CNT: "1",
            },
          }
        )
        .then((response) => {
          let jsonObj = JSON.parse(JSON.stringify(response.data.DATA));
          this.gridDataText = jsonObj;

          console.log("데이터 : " + JSON.stringify(response.data.DATA));

          head = JSON.stringify(response.data.HEADER);
          dataa = JSON.stringify(response.data.DATA);

          this.contactDetail = [];
          this.checkedList = [];
        })
        .catch((err) => {
          alert(err);
        });

      this.HEADERResult = head;
      this.DATAResult = dataa;
    },
  },
  computed: {
    dateRangeText() {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + " ~ " + newEndDate;
    },
    ...mapGetters({
      aspCustList: "userStore/GE_USER_COMPANY",
    }),
  },
};
</script>

<style></style>
